import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";

import { INGAppBarProps, MenuStyle } from "../../library/NGFieldExtensions";
import NGVisibleMenu from "../NGVisibleMenu/NGVisibleMenu";
import NGButton from "../NGButton/NGButton";
import { Button } from "../../../resolvers-types";
import { useSignal } from "@preact/signals-react";
import jbiLogo from "../../images/jbi-logo.svg";

export default function NGAppBar({ config, context, menu }: INGAppBarProps) {
  const open = useSignal(false);

  const handleClose = () => {
    console.log("handleClose, closing drawer");
    open.value = false;
  };

  const buttonRefreshConfig: Button = {
    __typename: "Button",
    Id: "topMenu-btnRefresh",
    Name: "topMenu-btnRefresh",
    StartIcon: { IconName: "Refresh" },
    Style: { color: "white" }, // TODO: get from theme
    Actions: [
      {
        Trigger: "onClick",
        CommandSet: {
          FirstCommandId: "1",
          ExecuteCommandsInParallel: false,
          Commands: [
            {
              Id: "1",
              Instruction: {
                Name: "ReloadPage",
              },
              Parameters: [],
            },
          ],
        },
      },
    ],
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: config.Width,
          height: config.Height,
          marginLeft: config.MarginLeft,
          marginRight: config.MarginRight,
          marginTop: config.MarginTop,
          marginBottom: config.MarginBottom,
        }}
        variant={config.Variant as any}
      >
        <Toolbar>
          <img src={jbiLogo} alt="SVG description" />
          <IconButton
            color="inherit"
            size="large"
            onClick={() => (open.value = true)}
            sx={{ display: { xs: "flex", sm: "none" } }}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>

          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {menu && (
              <NGVisibleMenu
                config={{ Data: menu }}
                handleClose={handleClose}
                menuStyle={MenuStyle.Horizontal}
                context={context}
              />
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <NGButton config={buttonRefreshConfig} context={context} />
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer open={open.value} anchor="left" onClose={handleClose} sx={{ display: { xs: "flex", sm: "none" } }}>
        <Box sx={{ width: 250 }}>
          {menu && (
            <NGVisibleMenu config={menu} handleClose={handleClose} menuStyle={MenuStyle.Vertical} context={context} />
          )}
        </Box>
      </Drawer>
    </>
  );
}
