import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Box } from "@mui/material";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { INGFileUploadProps } from "../../library/NGFieldExtensions";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { useRef } from "react";
import { setupLocalState } from "../../library/dataService";
import { client } from "../../library/nats-client";
import { getTempFileName, getTestId } from "../../library/utils";
import { isArray, isEmpty } from "lodash-es";

type FilePayload = {
  Name: string;
  Size: string;
  LastModified: Date;
  ContentType: string;
};

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const tag = "NGFileUpload";

export default function NGFileUpload({ config, context }: INGFileUploadProps) {
  const pondRef = useRef<FilePond>();

  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? []),
      LabelIdle: useSignal(config.LabelIdle || null),
    },
    context
  );

  useSignalEffect(() => {
    if (isEmpty(local.Value.value)) {
      pondRef.current?.removeFiles();
    } else {
      // pondRef.current?.addFiles(local.Value.value.filter((x) => x.Type == "limbo"));
    }
  });

  const addFile = (obj) => {
    local.Value.value = isArray(local.Value.value) ? local.Value.value.concat(obj) : [obj];
  };

  const deletFile = (name) => {
    local.Value.value = local.Value.value.filter((x) => x.Name != name);
  };

  const server = {
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      const id = getTempFileName(file.type);
      const reader = file.stream().getReader();
      let loaded = 0;
      const stream = new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                load(id);
                controller.close();

                addFile({
                  Id: id,
                  Name: file.name,
                  Size: file.size,
                  LastModified: new Date(file.lastModified),
                  ContentType: file.type,
                  Type: "limbo",
                });
                return;
              }

              controller.enqueue(value);
              loaded += value.length;
              progress(true, loaded, file.size);
              return pump();
            });
          }
        },
      });

      // client.objPut("temp", id, file.name, "Temp file", file.size, stream).then((r) => {
      client.objPut("temp", id, "Temp file", file.size, stream).then((r) => {
        if (!r.success) {
          const msg = r.reasons?.map((x) => x.message).join("\n");
          console.log("Error", msg);
          error(msg);
        }
      });
    },

    revert(name, load, error) {
      client.objDelete("temp", name).then((result) => {
        deletFile(name);

        if (result.success) {
          load();
        } else {
          error("Couldn't delete file");
        }
      });
    },
  };

  return (
    <Box
      data-testid={getTestId(config)}
      data-type={config.__typename}
      sx={{
        "& .filepond--root": {
          marginBottom: 0,
        },
      }}
    >
      <FilePond
        ref={pondRef}
        server={server}
        credits={false}
        {...(config.AllowInitFiles && {
          files: local.Value.value.map((x) => ({
            source: x.Id,
            options: { type: x.Type, file: { name: x.Name, size: x.Size, type: x.ContentType } },
          })),
        })}
        {...(local.LabelIdle.value && {
          labelIdle: local.LabelIdle.value as string,
        })}
        {...(config.DropOnPage && { dropOnPage: config.DropOnPage as boolean })}
        {...(config.MaxParallelUploads && {
          maxParallelUploads: config.MaxParallelUploads as number,
        })}
        {...(config.DropOnElement && {
          dropOnElement: config.DropOnElement as boolean,
        })}
        {...(config.DropValidation && {
          dropValidation: config.DropValidation as boolean,
        })}
        {...(config.IgnoredFiles && {
          ignoredFiles: config.IgnoredFiles as string[],
        })}
        {...(config.InstantUpload && {
          instantUpload: config.InstantUpload as boolean,
        })}
        {...(config.ChunkUploads && {
          chunkUploads: config.ChunkUploads as boolean,
        })}
        {...(config.ChunkForce && { chunkForce: config.ChunkForce as boolean })}
        {...(config.ChunkSize && { chunkSize: config.ChunkSize as number })}
        {...(config.ChunkRetryDelays && {
          chunkRetryDelays: config.ChunkRetryDelays as number[],
        })}
        {...(config.LabelDecimalSeparator && {
          labelDecimalSeparator: config.LabelDecimalSeparator as string,
        })}
        {...(config.LabelThousandsSeparator && {
          labelThousandsSeparator: config.LabelThousandsSeparator as string,
        })}
        {...(config.LabelInvalidField && {
          labelInvalidField: config.LabelInvalidField as string,
        })}
        {...(config.LabelFileWaitingForSize && {
          labelFileWaitingForSize: config.LabelFileWaitingForSize as string,
        })}
        {...(config.LabelFileSizeNotAvailable && {
          labelFileSizeNotAvailable: config.LabelFileSizeNotAvailable as string,
        })}
        {...(config.LabelFileCountSingular && {
          labelFileCountSingular: config.LabelFileCountSingular as string,
        })}
        {...(config.LabelFileCountPlural && {
          labelFileCountPlural: config.LabelFileCountPlural as string,
        })}
        {...(config.LabelFileLoading && {
          labelFileLoading: config.LabelFileLoading as string,
        })}
        {...(config.LabelFileAdded && {
          labelFileAdded: config.LabelFileAdded as string,
        })}
        {...(config.LabelFileLoadError && {
          labelFileLoadError: config.LabelFileLoadError,
        })}
        {...(config.LabelFileRemoved && {
          labelFileRemoved: config.LabelFileRemoved as string,
        })}
        {...(config.LabelFileRemoveError && {
          labelFileRemoveError: config.LabelFileRemoveError,
        })}
        {...(config.LabelFileProcessing && {
          labelFileProcessing: config.LabelFileProcessing as string,
        })}
        {...(config.LabelFileProcessingComplete && {
          labelFileProcessingComplete: config.LabelFileProcessingComplete as string,
        })}
        {...(config.LabelFileProcessingAborted && {
          labelFileProcessingAborted: config.LabelFileProcessingAborted as string,
        })}
        {...(config.LabelFileProcessingError && {
          labelFileProcessingError: config.LabelFileProcessingError,
        })}
        {...(config.LabelFileProcessingRevertError && {
          labelFileProcessingRevertError: config.LabelFileProcessingRevertError,
        })}
        {...(config.LabelTapToCancel && {
          labelTapToCancel: config.LabelTapToCancel as string,
        })}
        {...(config.LabelTapToRetry && {
          labelTapToRetry: config.LabelTapToRetry as string,
        })}
        {...(config.LabelTapToUndo && {
          labelTapToUndo: config.LabelTapToUndo as string,
        })}
        {...(config.LabelButtonRemoveItem && {
          labelButtonRemoveItem: config.LabelButtonRemoveItem as string,
        })}
        {...(config.LabelButtonAbortItemLoad && {
          labelButtonAbortItemLoad: config.LabelButtonAbortItemLoad as string,
        })}
        {...(config.LabelButtonRetryItemLoad && {
          labelButtonRetryItemLoad: config.LabelButtonRetryItemLoad as string,
        })}
        {...(config.LabelButtonAbortItemProcessing && {
          labelButtonAbortItemProcessing: config.LabelButtonAbortItemProcessing as string,
        })}
        {...(config.LabelButtonUndoItemProcessing && {
          labelButtonUndoItemProcessing: config.LabelButtonUndoItemProcessing as string,
        })}
        {...(config.LabelButtonRetryItemProcessing && {
          labelButtonRetryItemProcessing: config.LabelButtonRetryItemProcessing as string,
        })}
        {...(config.LabelButtonProcessItem && {
          labelButtonProcessItem: config.LabelButtonProcessItem as string,
        })}
        {...(config.IconRemove && { iconRemove: config.IconRemove as string })}
        {...(config.IconProcess && {
          iconProcess: config.IconProcess as string,
        })}
        {...(config.IconRetry && { iconRetry: config.IconRetry as string })}
        {...(config.IconUndo && { iconUndo: config.IconUndo as string })}
        {...(config.IconDone && { iconDone: config.IconDone as string })}
        {...(config.StylePanelLayout && {
          stylePanelLayout: config.StylePanelLayout as any,
        })}
        {...(config.StylePanelAspectRatio && {
          stylePanelAspectRatio: config.StylePanelAspectRatio,
        })}
        {...(config.StyleItemPanelAspectRatio && {
          styleItemPanelAspectRatio: config.StyleItemPanelAspectRatio,
        })}
        {...(config.StyleButtonRemoveItemPosition && {
          styleButtonRemoveItemPosition: config.StyleButtonRemoveItemPosition as string,
        })}
        {...(config.StyleButtonProcessItemPosition && {
          styleButtonProcessItemPosition: config.StyleButtonProcessItemPosition as string,
        })}
        {...(config.StyleLoadIndicatorPosition && {
          styleLoadIndicatorPosition: config.StyleLoadIndicatorPosition as string,
        })}
        {...(config.StyleProgressIndicatorPosition && {
          styleProgressIndicatorPosition: config.StyleProgressIndicatorPosition as string,
        })}
        {...(config.StyleButtonRemoveItemAlign !== undefined && {
          styleButtonRemoveItemAlign: config.StyleButtonRemoveItemAlign as boolean,
        })}
        {...(config.StyleButtonProcessItemAlign !== undefined && {
          styleButtonProcessItemAlign: config.StyleButtonProcessItemAlign as string,
        })}
        {...(config.AllowReplace && {
          allowReplace: config.AllowReplace as boolean,
        })}
        {...(config.AllowMultiple && {
          allowMultiple: config.AllowMultiple as boolean,
        })}
        {...(config.MaxFiles && { maxFiles: config.MaxFiles as number })}
        {...(config.CheckValidity && {
          checkValidity: config.CheckValidity as boolean,
        })}
        {...(config.ItemInsertLocationFreedom && {
          itemInsertLocationFreedom: config.ItemInsertLocationFreedom as boolean,
        })}
        {...(config.ItemInsertLocation && {
          itemInsertLocation: config.ItemInsertLocation as any,
        })}
        {...(config.ItemInsertInterval && {
          itemInsertInterval: config.ItemInsertInterval as number,
        })}
        {...(config.FileSizeBase && {
          fileSizeBase: config.FileSizeBase as number,
        })}
        {...(config.StoreAsFile && {
          storeAsFile: config.StoreAsFile as boolean,
        })}
      />
    </Box>
  );
}
