import { Icon } from "../../../resolvers-types";
import { INGButtonProps } from "../../library/NGFieldExtensions";
import { Button as MUIButton } from "@mui/material";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import NGIcon from "../NGIcon/NGIcon";
import { Stack } from "@mui/system";
import { getTestId, getsxObject, getClassName, getCustomLabel, isNullOrEmpty } from "../../library/utils";
import { isNil } from "lodash-es";

export default function NGButton({ config, context }: INGButtonProps) {
  const testId = getTestId(config);

  if (!isNil(config.StartIcon)) config.StartIcon.ContextId = testId;
  if (!isNil(config.EndIcon)) config.EndIcon.ContextId = testId;
  if (!isNil(config.TopIcon)) config.TopIcon.ContextId = testId;
  if (!isNil(config.BottomIcon)) config.BottomIcon.ContextId = testId;

  const local = setupLocalState(
    config,
    {
      Label: useSignal(config.Label),
      Color: useSignal(config.Color),
      Disabled: useSignal(config.Disabled ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
      StartIcon: useSignal(config.StartIcon ?? {}),
      EndIcon: useSignal(config.EndIcon ?? {}),
      BottomIcon: useSignal(config.BottomIcon ?? {}),
      TopIcon: useSignal(config.TopIcon ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  // AA-TODO: Find a better way to deal with button width in design mode
  const width = context.InDesignMode && local.Style.value.Width == undefined ? "100%" : local.Style.value.Width;

  return (
    <>
      {local.Visible.value && (
        <MUIButton
          sx={getsxObject(local.Style.value, {
            textTransform: "none",
            width,
          })}
          startIcon={
            local.StartIcon.value && !isNullOrEmpty(local.StartIcon.value.IconName) && (
              <NGIcon config={{ ...local.StartIcon.value, ContextId: testId }} context={context} />
            )}
          data-testid={testId}
          data-type={config.__typename}
          endIcon={
            local.EndIcon.value && !isNullOrEmpty(local.EndIcon.value.IconName) && (
              <NGIcon config={{ ...local.EndIcon.value, ContextId: testId }} context={context} />
            )}
          className={getClassName(local.Classes)}
          color={local.Color.value}
          disabled={local.Disabled.value}
          disableElevation={config.DisableElevation as boolean}
          disableFocusRipple={config.DisableFocusRipple as boolean}
          disableRipple={config.DisableRipple as boolean}
          fullWidth={config.FullWidth as boolean}
          href={config.Href as string}
          size={config.Size as any}
          centerRipple={config.CenterRipple as boolean}
          disableTouchRipple={config.DisableTouchRipple as boolean}
          focusRipple={config.FocusRipple as boolean}
          focusVisibleClassName={config.FocusVisibleClassName as string}
          variant={config.Variant as any}
          {...handlers}
        >
          <Stack direction="column" spacing={1} alignItems="center">
            {local.TopIcon.value && !isNullOrEmpty(local.TopIcon.value.IconName) && (
              <NGIcon config={{ ...local.TopIcon.value, ContextId: testId }} context={context} />
            )}
            {getCustomLabel(local.Label.value)}
            {local.BottomIcon.value && !isNullOrEmpty(local.BottomIcon.value.IconName) && (
              <NGIcon config={{ ...local.BottomIcon.value, ContextId: testId }} context={context} />
            )}
          </Stack>
        </MUIButton>
      )}
    </>
  );
}
