type JsonValue = string | number | boolean | null | JsonArray;
type JsonArray = Array<JsonValue>

interface Filter {
  Name: string;
  Value: string | number | boolean; // Adjust based on expected types
  Operator: string;
}

class QueryRequest {
  Filter?: Filter[] = [];
  GroupBy?: string[] = [];
  Aggregate?: { [key: string]: string[] } = {};
  OrderBy?: string[] = [];
  Top = 1000;
  Skip = 0;
  Select: string[] = [];
  // odata: { [key: string]: string } = {};
  private aggregatedFields: string[] = [];

  toODataQuery(): { [key: string]: string } {
    const groupByQuery = this.getODataGroupQuery();
    const filterQuery = this.getODataFilter(false, this.aggregatedFields);

    const queryResult: { [key: string]: string } = {};

    if (this.OrderBy && this.OrderBy.length > 0) {
      queryResult["$orderby"] = this.OrderBy.join(",");
    }

    if (filterQuery) {
      queryResult["$filter"] = filterQuery;
    }

    if (groupByQuery) {
      queryResult["$apply"] = groupByQuery;
    }

    if (this.Select.length > 0) {
      queryResult["$select"] = this.Select.join(",");
    } else {
      queryResult["$select"] = "*";
    }

    queryResult["$skip"] = this.Skip.toString();
    queryResult["$top"] = this.Top.toString();

    return queryResult;
  }

  private getODataFilter(getAggregationFilter: boolean, aggregationFilter: string[]): string {
    if (!this.Filter || this.Filter.length === 0) {
      return '';
    }

    const containsOperator = ["contains", "not contains", "startswith"];
    const filter = this.Filter
      .filter(f => getAggregationFilter
        ? aggregationFilter.includes(f.Name)
        : !aggregationFilter.includes(f.Name))
      .map(f => {
        if (containsOperator.includes(f.Operator.toLowerCase())) {
          return `${f.Operator}(${f.Name},${this.getODataValueTyped(f.Value)})`;
        }

        if (f.Operator.toLowerCase() === "in") {
          return `${f.Name} in (${this.getODataValueTyped(f.Value)})`;
        }

        if (f.Operator.toLowerCase() === "not in") {
          return `not (${f.Name} in (${this.getODataValueTyped(f.Value)}))`;
        }

        return `${f.Name} ${f.Operator} ${this.getODataValueTyped(f.Value)}`;
      });

    return filter.join(" and ");
  }

  private getODataGroupQuery(): string {
    if (!this.GroupBy || this.GroupBy.length === 0) {
      return '';
    }

    const groupBy = this.GroupBy.join(",");
    let aggregate = '';

    if (Object.keys(this.Aggregate || {}).length > 0) {
      const innerAggregate = Object.entries(this.Aggregate || {}).map(([key, values]) => {
        const capitalizedPrefix = key.charAt(0).toUpperCase() + key.slice(1);

        if (key.toLowerCase() === "count") {
          this.aggregatedFields.push(`${capitalizedPrefix}${values[0]}`);
          return `$count as ${capitalizedPrefix}${values[0]}`;
        }

        const fields = values.map(field => {
          this.aggregatedFields.push(`${capitalizedPrefix}${field}`);
          return `${field} with ${key} as ${capitalizedPrefix}${field}`;
        });

        return fields.join(",");
      }).join(",");

      aggregate = `,aggregate(${innerAggregate})`;
    }

    let groupByFilter = "";
    const filterValue = this.getODataFilter(true, this.aggregatedFields);
    if (filterValue) {
      groupByFilter = `/filter(${filterValue})`;
    }

    return `groupby((${groupBy})${aggregate})${groupByFilter}`;
  }

  private getODataValueTyped(value: JsonValue): string {
    if (typeof value === 'string') {
      return `'${value}'`;
    } else if (typeof value === 'number' || value === null) {
      return value !== null ? value.toString() : 'null';
    } else if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    } else if (Array.isArray(value)) {
      if (value.every(v => typeof v === 'string')) {
        return value.map(v => `'${v}'`).join(",");
      }
      return value.map(v => JSON.stringify(v)).join(",");
    }
    return JSON.stringify(value);
  }
}

export default QueryRequest;