import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { INGFeatureSetProps, MenuStyle } from "../../library/NGFieldExtensions";
import NGFeature from "../NGFeature/NGFeature";
import { getTestId, getURLForFeature } from "../../library/utils";
import NGIcon from "../NGIcon/NGIcon";
import { Icon } from "../../../resolvers-types";
import { useNavigate } from "react-router-dom";
import { useSignal } from "@preact/signals-react";
import { setupLocalState } from "../../library/dataService";

function NGFeatureSetHorizontal({ config, context }: INGFeatureSetProps) {

  const local = setupLocalState(
    config,
    {
      ButtonStyle: useSignal(config.ButtonStyle ?? {}),
    },
    context
  );
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
  const selectedMenuId = useSignal<string | null>(null);

  const setSelectedMenuId = (id: string | null) => {
    selectedMenuId.value = id;
  }

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      (config.Features && config.Features.length) ||
      !config.AllowNavigation
    ) {
      setAnchorEl(event.currentTarget);
      setSelectedMenuId(config.Id);
    } else {
      navigate(getURLForFeature(config));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenuId(null);
  };

  const invertColor = (hex) => {
    if (hex.startsWith("#")) hex = hex.slice(1);
    const r = 255 - parseInt(hex.slice(0, 2), 16);
    const g = 255 - parseInt(hex.slice(2, 4), 16);
    const b = 255 - parseInt(hex.slice(4, 6), 16);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  };

  return (
    <>
      <Button
        data-testid={getTestId(config)}
        color="inherit"
        key={"Btn_" + config.Id}
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={[
          local.ButtonStyle.value,
          {
            backgroundColor: local.ButtonStyle.value.focus && (selectedMenuId.value === config.Id ? local.ButtonStyle.value.focus : "inherit"),
            color: local.ButtonStyle.value.color && (selectedMenuId.value === config.Id ? invertColor(local.ButtonStyle.value.color) : "inherit"),
          }
        ]}
        startIcon={
          config.StartIcon && (
            <NGIcon config={config.StartIcon as Icon} context={context} />
          )
        }
        endIcon={
          config.EndIcon && (
            <NGIcon config={config.EndIcon as Icon} context={context} />
          )
        }
      >
        {config.Label}
      </Button>
      <Menu
        key={"Mnu_" + config.Id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {config.Features?.map((item) => {
          item.ContextId = config.ContextId;
          item.AllowNavigation = config.AllowNavigation ?? true;
          return (
            <NGFeature
              key={item.Id}
              config={item}
              handleClose={handleClose}
              context={context}
            />
          );
        })}
      </Menu>
    </>
  );
}


function NGFeatureSetVertical({
  config,
  context,
  handleClose,
}: INGFeatureSetProps) {
  return (
    <>
      {config.Label}
      {config.Features?.map((feature) => {
        feature.ContextId = config.ContextId;
        return (
          <NGFeature
            key={feature.Id}
            config={feature}
            handleClose={handleClose}
            context={context}
          />
        );
      })}
    </>
  );
}
export default function NGFeatureSet(props: INGFeatureSetProps) {
  switch (props.menuStyle) {
    case MenuStyle.Vertical:
      return <NGFeatureSetVertical {...props} />;
    case MenuStyle.Horizontal:
      return <NGFeatureSetHorizontal {...props} />;
  }
}
