import {
  INGVisibleMenuProps,
  MenuStyle,
} from "../../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import NGFeatureSet from "../NGFeatureSet/NGFeatureSet";
import { Skeleton, Stack } from "@mui/material";
import { Feature, VisibleMenu } from "../../../resolvers-types";
import { getsxObject, getTestId } from "../../library/utils";
import { setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import { log } from "../../library/logger";

export default function NGVisibleMenu({
  config,
  context,
  handleClose = () => null,
  menuStyle = MenuStyle.Horizontal,
}: INGVisibleMenuProps) {
  const local = setupLocalState(
    config,
    {
      Data: useSignal(config.Data ?? {}),
      Loading: useSignal(config.Loading ?? false),
      Style: useSignal(config.Style ?? {}),
      ButtonStyle: useSignal(config.ButtonStyle ?? {}),
    },
    context
  );

  if (
    isNil(local.Data.value?.Features) ||
    local.Data.value?.Features.length === 0
  ) {
    // log.error("No features found in menu", config);
    return <div>NO MENU DATA</div>;
  }
  if (local.Loading.value)
    return <Skeleton variant="rectangular" width={800} height={40} />;

  return (
    <Stack
      direction={menuStyle == MenuStyle.Vertical ? "column" : "row"}
      spacing={menuStyle == MenuStyle.Vertical ? 1 : 2}
      sx={getsxObject(local.Style.value)}
      data-testid={getTestId(config)}
    >
      {(local.Data.value?.Features ?? []).map((feature) => {
        (feature as Feature).ContextId = config.Id;
        (feature as Feature).AllowNavigation =
          (config as VisibleMenu).AllowNavigation ?? true;
        return (
          <NGFeatureSet
            key={feature?.Id}
            config={{ ...feature, ButtonStyle: config.ButtonStyle } as Feature}
            menuStyle={menuStyle}
            handleClose={handleClose}
            context={context}
          />
        );
      })}
    </Stack>
  );

  //TODO: support icons
  //<ListItemIcon>{item.icon}</ListItemIcon>
  /*
      switch (menuStyle) {
          case MenuStyle.Vertical:
              return (
                  <Stack direction="column" sx={{ width: 250 }}>
                      {menu.FeatureSets.map((featureSet) => (
                          <NGFeatureSet featureSet={featureSet} menuStyle={menuStyle} handleClose={handleClose} />
                      ))}
                  </Stack>
              )
          case MenuStyle.Horizontal:
              return (
                  <Stack direction="row" spacing={2} sx={{ display: { xs: "none", sm: "flex" } }}>
                      {menu.FeatureSets.map((featureSet) => (
                          <NGFeatureSet featureSet={featureSet} menuStyle={menuStyle} handleClose={handleClose}/>
                      ))}
                  </Stack>
              )
      }
      */
}
/*
        
                    {menu.FeatureSets.map((featureSet) => (
                <>
                    <ListItemButton onClick={handleClick}>
                        <ListItemText primary={featureSet.Label} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                        {featureSet.Features?.map((feature) => (
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    <NGFeature feature={feature} handleClose={handleClose as ()=>void} />
                                </ListItemButton>
                            </List>
                        ))}
                    </Collapse>
                </>

            ))}
            */
