import {
  LoaderFunctionArgs,
  Outlet,
  RouteObject,
  createBrowserRouter,
  isRouteErrorResponse,
  redirectDocument,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import {
  setupLocalState,
  setupHandlers,
  NGNextPage,
  clearState,
  setupPage,
  getState,
  getDefaultContext,
  ClearTheme,
  GetThemeFromServer,
  GetTemplateFromServer,
  // local,
  // formState,
} from "./library/dataService";
import { Layout, Menu, Page, PageTemplate, Theme } from "./../resolvers-types";
import { anonymousSiteConfig, siteConfig } from "./sampleData/siteSample";
import NGPage from "./generators/NGPage";
import { ngEditorMenu } from "./sampleData/ngEditorMenu";
import {
  getNextImpersonationTabId,
  getRepositoryAssetsBaseUrl,
  isNullOrEmpty,
  parseAuthCookies,
  setCookie,
  setupAppTitle,
  setupFavicon,
  updateFavicon,
} from "./library/utils";
import NGPageTemplate from "./components/NGPageTemplate/NGPageTemplate";
import { isNil } from "lodash-es";
import { client } from "./library/nats-client";
import { Result } from "./models/result";
import { useEffect } from "react";
import { signal, useSignal, useSignalEffect } from "@preact/signals-react";
import { setupReferencedComponentsAndServicesInpage } from "./library/components";
import { LogTag, log } from "./library/logger";
import NGDesignOverlay from "./generators/NGDesignOverlay";
import { Box, GlobalStyles, ThemeProvider, createTheme } from "@mui/material";
import { designerState, setupDesigner } from "./library/designer";
import { setupAuth, useOidc } from "./library/auth";
import { NGAutoLogoutCountdown } from "./components/NGAutoLogoutCountdown/NGAutoLogoutCountdown";
import { isNative } from "./library/native";
import { themes } from "./theme";
import NGOverlay from "./components/NGOverlay/NGOverlay";
import { impersonateUserOverlay as impersonatingOverlay } from "./sampleData/impersonatingUserOverlay.ts";

const tag: LogTag = "NGRouter";

type ParentState = {
  Authenticated: boolean;
  Menu: Menu;
  Template: PageTemplate;
  Theme: Theme;
  SessionId?: string;
};

const ParentRoute = () => {
  const config = useLoaderData() as ParentState;

  const navigate = useNavigate();
  const location = useLocation();

  const context = getDefaultContext();

  useSignalEffect(() => {
    if (isNil(NGNextPage.value)) return;

    navigate(NGNextPage.value);

    NGNextPage.value = null;
  });

  useEffect(() => {
    if (isNil(config.Menu.LandingPageUrl)) return;

    if (location.pathname === "/") {
      navigate(config.Menu.LandingPageUrl);
    }
    // redirect impersonated user to their landing page
    if (!isNullOrEmpty(config.SessionId) && location.pathname === `/u/${config.SessionId}/`) {
      const landingPage = config.Menu.LandingPageUrl.startsWith("/") ? config.Menu.LandingPageUrl.substring(1) : config.Menu.LandingPageUrl;
      navigate(`/u/${config.SessionId}/${landingPage}`);
    }
  }, [config.Menu.LandingPageUrl, config.SessionId, location.pathname, navigate]);

  const currentTheme = themes["default"];

  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <GlobalStyles styles={{ ...currentTheme?.styles, ...config.Theme?.Styles }} />
        <NGAutoLogoutCountdown />
        <NGOverlay key="impersonating-overlay" config={impersonatingOverlay} context={context} />
        <NGPageTemplate config={config.Template} menu={config.Menu} context={context}>
          {NGNextPage.value == null && <Outlet />}
        </NGPageTemplate>
      </ThemeProvider>
    </>
  );
};

async function GetMenuFromBackend(menuId) {
  log.info(tag, "Service request", "project.menu.get");
  const resp = (await client.request("project.menu.get", {
    Id: menuId,
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "Service project.menu.get failed", resp?.reasons);
    return false;
  }

  return resp?.data.Entity;
}

async function GetDefaultMenu() {
  log.info(tag, "Service request", "project.menu.get");
  const resp = (await GetMenuFromBackend("default")) as Result<any>;
  if (!resp) {
    log.error(tag, "Service project.menu.get failed", resp);
    return false;
  }

  return resp;
}

async function GetUserMenu(userId) {
  // when not found an menu metadata for userId need to return default menu
  log.info(tag, "Service request", "userprofile.get", userId);
  const userProfileResp = (await client.request("userprofile.get", {
    Id: userId,
  })) as Result<any>;
  if (!userProfileResp?.success) {
    log.error(tag, "Service userprofile.get failed", userProfileResp?.reasons);
    return false;
  }

  const userMenuId = userProfileResp?.data.Details?.MenuId;

  // If the user didn't have a menu, then return the default menu
  if (!userMenuId) {
    log.warn(tag, "User does not have a menu assigned. Using default menu.");
    return GetDefaultMenu();
  }

  // If the user did have an assigned menu, get it
  const menuResp = (await GetMenuFromBackend(userMenuId)) as Result<any>;
  if (!menuResp) {
    log.error(tag, "GetMenuFromBackend failed", menuResp);
    return false;
  }

  log.info(tag, "GetUserMenu returned menu Id", userMenuId);
  return menuResp;
}

async function GetDynamicPageList() {
  log.info(tag, "Service request", "project.page.query");
  const resp = (await client.request("project.page.query", {})) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "Service project.page.query failed", resp?.reasons);
    return false;
  }
  return resp?.data.Entities;
}

async function GetDynamicPage(pageSlug) {
  log.info(tag, "Service request", "project.page.get", pageSlug);
  const resp = (await client.request("project.page.get", {
    Id: pageSlug,
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "Service project.page.get failed", resp?.reasons);
    return false;
  }
  return resp?.data.Entity;
}

// TODO: this function will be deprecated once all pages are stored server side
async function GetStaticPage(pageSlug) {
  //const fileName = slugToCamelCase(pageSlug);
  const page = await import(`./sampleData/${pageSlug}/${pageSlug}.ts`);
  const theme = await GetThemeFromServer();

  if (!isNil(theme) && !isNil(theme.Styles)) page.default.Styles = { ...theme.Styles, ...page.default.Styles };

  return page.default as Page;
}

const ChildRoute = () => {
  const pageMetadata: Page = useLoaderData() as Page; // Use the hook to get the pre-loaded data
  const context = getDefaultContext();

  if (isNil(pageMetadata)) return <div />;

  return <NGPage config={pageMetadata} layout={pageMetadata.Layout as Layout} context={context} />;
};

const EditorRoute = () => {
  const config = useLoaderData() as any; // Use the hook to get the pre-loaded data

  const context = getDefaultContext();

  context.InDesignMode = true;
  const theme = useSignal(config.theme);

  const local = setupLocalState(
    {
      Bindings: {
        Page: "State.Page",
        UndoRedoPageStackIndex: "State.UndoRedoPageStackIndex",
        // ShowInvisibleComponents: "State.EditorShowInvisibleComponents",
      },
    },
    {
      Page: useSignal(null),
      UndoRedoPageStackIndex: useSignal(0),
    },
    context
  );

  function handleMessage(message) {
    const msg = message.data;
    if (msg.Source !== "ng") return;

    const handlers = setupHandlers(msg, context);

    const { global } = getState(context);
    log.debug(tag, "Editor frame handle message", msg, global);

    if (msg.Type == "stylesUpdated") {
      ClearTheme();
      GetThemeFromServer().then((theme2) => {
        theme.value = theme2;
      });
    } else if (msg.Type == "selectItems") {
      designerState.Selected.value = msg.Selected;
    } else if (msg.Type == "toggleInvisibleComponents") {
      designerState.ShowInvisibleComponents.value = msg.Value;
    } else if (msg.Type == "toggleIgnoreSampleDataInEditor") {
      designerState.IgnoreSampleDataInEditor.value = msg.Value;
      local.Page.value = { ...local.Page.value };
    } else if (msg.Type == "setInteractionMode") {
      designerState.InteractionMode.value = msg.Value;
      // local.Page.value = { ...local.Page.value };
    }

    if (handlers[msg.Type]) {
      handlers[msg.Type](msg, msg);
    }
  }

  // useSignalEffect(() => {
  //   //context.DesignProps = { ...context.DesignProps, ...{ AllVisible: local.ShowInvisibleComponents.value } };
  //   designerState.ShowInvisibleComponents.value = local.ShowInvisibleComponents.value;
  // });

  useSignalEffect(() => {
    const ignore = theme.value;
    setupDesigner(local.Page.value);
  });

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    window.parent.postMessage(
      {
        Source: "ng",
        Type: "onInit",
        Data: null,
      },
      "*",
      []
    );

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Box
      className="designer"
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <GlobalStyles styles={theme.value?.Styles} />
      {local.Page.value && (
        <>
          <NGPage config={local.Page.value} layout={local.Page.value?.Layout} context={context} />
          <NGDesignOverlay config={local.Page.value} context={context} />
        </>
      )}
    </Box>
  );
};

async function GetMenu(userProfile) {
  const menuMetadata = await GetUserMenu(userProfile.Id);
  if (!menuMetadata) {
    log.error(tag, `Failed to get menu for user profile: ${JSON.stringify(userProfile)}`);
    return ngEditorMenu as Menu;
  }

  log.info(tag, "Menu", menuMetadata);
  return menuMetadata;
}

function ErrorElement() {
  const error = useRouteError();
  const oidc = useOidc();
  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      if (isNative()) {
        window.location.href = "/local/login";
      } else if (oidc != null) {
        oidc.logout({ redirectTo: "current page" });
      }
      return <></>;
    }

    log.error(tag, "Error", error);
    return (
      <div>
        <h2>{error.status}</h2>
        <div>{error.data.Message}</div>
      </div>
    );
  } else {
    <pre>{JSON.stringify(error)}</pre>;
  }
}

function ImpersonateErrorElement() {
  return (
    <div>
      <h2>Impersonation failed</h2>
    </div>
  );
}

const useJwtAuth = import.meta.env.VITE_NATS_AUTH_JWT == "true";

const fallbackTemplate = {
  __typename: "PageTemplate",
  Id: "0xf021",
  UniqueName: "Fallback Template",
  Description: "Fallback Template",
  TopAppBar: {
    Id: "0xf022",
    UniqueName: "Top Navigation",
  },
};

const localLoader = async (args: LoaderFunctionArgs) => {
  const { request, params } = args;

  // Setup auth before loading anything to avoid a race
  const { global } = getState(getDefaultContext());
  const auth = await setupAuth(args, global);
  if (!auth?.isUserLoggedIn) {
    if (isNative() && params.routeId != "login") {
      return null;
    }
  }

  if (params.routeId == "login" && !isNative()) {
    return redirectDocument(`/`);
  }

  // Get the page for the feature
  const foundPage = await GetStaticPage(params.routeId);
  await setupReferencedComponentsAndServicesInpage(foundPage);

  clearState(request.url);
  setupPage(foundPage);
  return foundPage;
};

const interpretedPageLoader = async (args: LoaderFunctionArgs) => {
  const { request, params } = args;

  // Setup auth before loading anything to avoid a race
  const { global } = getState(getDefaultContext());
  const auth = await setupAuth(args, global);
  if (!auth?.isUserLoggedIn) {
    return null;
  }

  const page = await GetDynamicPage(params.routeId);
  await setupReferencedComponentsAndServicesInpage(page);

  clearState(request.url);
  if (page) {
    setupPage(page);
    return page;
  } else {
    return null;
  }
};

const editorLoader = async (args: LoaderFunctionArgs) => {
  const { request, params } = args;
  const { global } = getState(getDefaultContext());
  const auth = await setupAuth(args, global);
  if (!auth?.isUserLoggedIn) {
    return null;
  }

  clearState(request.url); // removes contamination from previous page state

  let theme = await GetThemeFromServer();

  if (isNil(theme)) theme = {};
  if (isNullOrEmpty(theme.Styles)) theme.Styles = {};
  if (isNullOrEmpty(theme.Styles.body)) theme.Styles.body = {};

  global["Menu"].value = await GetMenu(global["User"].value);

  return {
    mode: params.mode,
    theme: theme,
  };
};

const rootPathLoader = async (args) => {
  const { request, params } = args;
  const config = {
    Authenticated: false,
    Menu: {} as Menu | null,
    Template: anonymousSiteConfig,
    Theme: null,
    SessionId: null,
  };

  if (!isNil(params.sessionId)) {
    const userId = localStorage.getItem(`Api.Imp.${params.sessionId}.UserId`);

    if (isNullOrEmpty(userId)) {
      return redirectDocument(`/`);
    }
    else {
      config.SessionId = params.sessionId;
    }
  }

  const { global } = getState(getDefaultContext());
  const auth = await setupAuth(args, global);

  // if Keycloak return error redirect to login
  if (!isNil(auth?.initializationError)) {
    return redirectDocument(`/`);
  }

  if (!auth?.isUserLoggedIn) {
    if (isNative()) {
      const url = new URL(request.url);
      if (url.pathname != "/local/login") {
        // TODO - what do we do in the scenario where there is a user session ??
        // && !basePartRegex.test(url.pathname)

        return redirectDocument(`/local/login?Redirect=${url.pathname}${url.search}`);
      }
    }

    return config;
  }

  // Get the theme so that the Child routes can load it
  let theme = await GetThemeFromServer();

  if (isNil(theme)) theme = {};
  if (isNullOrEmpty(theme.Styles)) theme.Styles = {};
  if (isNullOrEmpty(theme.Styles.body)) theme.Styles.body = {};
  setupFavicon(theme);
  setupAppTitle();

  config.Menu = await GetMenu(global["User"].value);
  global["Menu"].value = config.Menu;

  let template = await GetTemplateFromServer();
  if (isNil(template)) template = fallbackTemplate;

  config.Template = template;
  config.Theme = theme;

  return config;
};

const routes = [
  {
    path: "/impersonate",
    loader: async ({ request }) => {
      const n = getNextImpersonationTabId();

      const tabId = n.toString();

      const impersonateId = new URL(request.url).searchParams.get("ImpersonationRequestId");
      if (!isNil(impersonateId)) {
        const resp = (await client.request("impersonationrequest.get", { Id: impersonateId })) as Result<any>;
        if (!resp?.success) {
          log.error(tag, "Service impersonationrequest.get failed", resp?.reasons);
          throw new Error("Impersonation request not found");
        }

        if (typeof localStorage != "undefined") {
          // sessionStorage.setItem("impersonateId", impersonateId);
          localStorage.setItem(`Api.Imp.${tabId}.NatsJwt`, resp?.data.NatsJwt);
          localStorage.setItem(`Api.Imp.${tabId}.NatsSeed`, resp?.data.NatsSeed);
          localStorage.setItem(`Api.Imp.${tabId}.RefreshToken`, resp?.data.RefreshToken);
          localStorage.setItem(`Api.Imp.${tabId}.UserId`, resp?.data.UserId);
        }

        setCookie(`Api.Imp.${tabId}.Token`, resp?.data.Token, 1);

        // force NATS to reconnect with the new credentials
        client.disconnect();
      }

      return redirectDocument(`/u/${tabId}/`);
    },
    element: <div>test</div>,
    errorElement: <ImpersonateErrorElement />,
  },
  {
    path: "/u/:sessionId/",
    element: <ParentRoute />,
    loader: rootPathLoader,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "/u/:sessionId/local/:routeId", //, "/u/1/local/:routeId"], // For static routes; this will disappear in the future
        loader: localLoader,
        element: <ChildRoute />,
      },
      {
        path: "/u/:sessionId/:routeId", // ["/:routeId", "/u/*/:routeId"], // For dynamic routes; this will disappear in the future
        loader: interpretedPageLoader,
        element: <ChildRoute />,
      },
    ] as RouteObject[],
  },
  {
    path: "/",
    element: <ParentRoute />,
    loader: rootPathLoader,
    errorElement: <ErrorElement />,
    children: [
      {
        path: "/local/:routeId", //, "/u/1/local/:routeId"], // For static routes; this will disappear in the future
        loader: localLoader,
        element: <ChildRoute />,
      },
      {
        path: "/:routeId", // ["/:routeId", "/u/*/:routeId"], // For dynamic routes; this will disappear in the future
        loader: interpretedPageLoader,
        element: <ChildRoute />,
      },
    ] as RouteObject[],
  },
  {
    path: "/ngeditor/:mode",
    loader: editorLoader,
    element: <EditorRoute />,
  },
  {
    path: "/u/:sessionId/ngeditor/:mode",
    loader: editorLoader,
    element: <EditorRoute />,
  },
];

export const NGRouter = createBrowserRouter(routes);
