import { MouseEvent, useRef } from "react";
import { Document, Page } from "react-pdf";
import { batch, useComputed, useSignal } from "@preact/signals-react";
import { SxProps, Theme } from "@mui/system";
import { Box, Button, Stack, Typography } from "@mui/material";
import NGIcon from "../NGIcon/NGIcon";
import { INGDocumentViewerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, getClassName } from "../../library/utils";
import { isNil, isString } from "lodash-es";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

const defaultStyle = (width = "fit-content") => ({
  width,
  position: "relative",
  "& .react-pdf__Page": {
    width,
  },
  "& .react-pdf__Document": {
    width,
  },
});

export default function NGDocumentViewer({ config, context }: INGDocumentViewerProps) {
  const local = setupLocalState(
    config,
    {
      NumPages: useSignal(0), // Initialize with 0, updated on document load
      CurrentPage: useSignal(1), // Signal to track the current page
      CurrentDocument: useSignal(-1), // Signal to track the current document
      PreviewMode: useSignal(config.PreviewMode ?? false),
      Scale: useSignal(config.Scale ?? 1),
      Visible: useSignal(config.Visible != false ? true : config.Visible),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? ""),
      Documents: useSignal(config.Documents ?? []),
      ButtonStyle: useSignal(config.ButtonStyle ?? {}),
      ButtonClasses: useSignal(config.ButtonClasses ?? []),
    },
    context
  );
  const paginationRef = useRef<HTMLDivElement>(null);
  const handlers = setupHandlers(config, context);

  function handleOnClick(event: MouseEvent<HTMLDivElement>) {
    if (!isNil(handlers["onClick"])) handlers["onClick"](new Event("click"), event);
  }

  function onDocumentLoadSuccess({ numPages }) {
    batch(() => {
      local.NumPages.value = numPages; // Set the total number of pages once the document is loaded
      local.CurrentPage.value = 1; // Reset the current page to 1
    });
  }

  function goToPrevPage() {
    if (local.CurrentPage.value > 1) {
      local.CurrentPage.value -= 1;
    }
  }

  function goToNextPage() {
    if (local.CurrentPage.value < local.NumPages.value) {
      local.CurrentPage.value += 1;
    }
  }

  function setDocument(index: number) {
    if (local.Value.value !== null)
      local.Value.value = local.Documents.value[index];
  }

  function goToNextDocument() {
    if (local.CurrentDocument.value < local.Documents.value.length - 1) {
      local.CurrentDocument.value += 1;
      setDocument(local.CurrentDocument.value);
    }
  }

  function goToPrevDocument() {
    if (local.CurrentDocument.value > 0) {
      local.CurrentDocument.value -= 1;
      setDocument(local.CurrentDocument.value);
    }
  }

  const selected = useComputed(() => {
    const vex = config.ValueExpression;
    const pex = config.PathExpression;
    if (isString(local.Value.value) && local.Value.value) return local.Value.value;
    if (!vex) return "";

    if (!local.Documents.value || local.Documents.value.length === 0) {
      if (!local.Value.value) return "";
      // value is object
      // Extract the value expression and path expression
      const valueExpression = local.Value.value?.[vex];
      // Combine the path expression and value expression
      return `${pex}${valueExpression}`;
    }
    if (!local.Value.value) {
      setDocument(0)
    }
    // get the index of the selected document
    const selectedDocumentIndex = local.Documents.value.findIndex((doc) => doc?.[vex] === local.Value.value?.[vex]);
    // if selected document is not found
    if (selectedDocumentIndex === -1 && local.Documents.value.length > 0) {
      if (local.CurrentDocument.value > 0) {
        // if the current document is not the first document
        // set previous document as the selected document
        goToPrevDocument();
        return;
      }
      // set the first document as the selected document
      setDocument(0);
      return;
    }
    // document found
    // when selected document is not the current document
    const selectedDocument = local.Documents.value[selectedDocumentIndex];
    local.CurrentDocument.value = selectedDocumentIndex;
    return `${pex}${selectedDocument?.[vex]}`;
  });

  return (
    local.Visible.value && (
      <>
        <Box
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value, defaultStyle(local.Style.value?.width)) as SxProps<Theme>}
          className={getClassName(local.Classes, context)}
          onClick={handleOnClick}
        >
          <Document
            file={selected.value}
            onLoadSuccess={onDocumentLoadSuccess}
            className={getClassName(local.Classes, context)}
            onMouseEnter={() => paginationRef.current?.style.setProperty("opacity", "1")}
            onMouseLeave={() => paginationRef.current?.style.setProperty("opacity", "0")}
          >
            <Page
              pageNumber={local.CurrentPage.value}
              scale={local.Scale.value}
              renderAnnotationLayer={!local.PreviewMode.value}
              renderTextLayer={!local.PreviewMode.value}
              style={local.Style.value}
            />
            {!local.PreviewMode.value && (
              <Stack
                direction="row"
                position="absolute"
                bottom="5%"
                bgcolor="white"
                left="50%"
                sx={{
                  transform: "translate(-50%)",
                  transition: "opacity ease-in-out .2s",
                  opacity: 0,
                }}
                boxShadow="0 30px 40px 0 rgba(16, 36, 94, .2)"
                borderRadius="4px"
                border="1px solid #E0E0E0"
                zIndex={2}
                ref={paginationRef}
              >
                <Button onClick={goToPrevPage} disabled={local.CurrentPage.value <= 1}>
                  <NGIcon config={{ IconName: "ChevronLeft" }} context={context} />
                </Button>
                <Typography component="p" marginBlockStart="1rem" marginBlockEnd="1rem">
                  Page {local.CurrentPage.value} of {local.NumPages.value}
                </Typography>
                <Button onClick={goToNextPage} disabled={local.CurrentPage.value >= local.NumPages.value}>
                  <NGIcon config={{ IconName: "ChevronRight" }} context={context} />
                </Button>
              </Stack>
            )}
          </Document>
        </Box>
        {local.Documents.value.length > 1 && local.CurrentDocument.value > -1 && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button
              onClick={goToPrevDocument}
              disabled={local.CurrentDocument.value === 0}
              className={getClassName(local.ButtonClasses)}
              sx={getsxObject(local.ButtonStyle.value)}
            >
              {"<<"} Previous Document
            </Button>
            <Button
              onClick={goToNextDocument}
              disabled={local.CurrentDocument.value === local.Documents.value.length - 1}
              className={getClassName(local.ButtonClasses)}
              sx={getsxObject(local.ButtonStyle.value)}
            >
              Next Document {">>"}
            </Button>
          </Stack>
        )}
      </>
    )
  );
}
